/* $main-panel
 ------------------------------------------*/
@use "sass:math";

$mat-toolbar-height-desktop: 64px !default;
$mat-toolbar-height-mobile-portrait: 56px !default;
$mat-toolbar-height-mobile-landscape: 48px !default;

.app {
  @include media-breakpoint-down(xs) {
    overflow: hidden;
  }
}
.app-inner {
  position: relative;
  width: 100%;
  max-width: 100%;
  // height: calc(100vh - #{$mat-toolbar-height-desktop});
  @include flexbox;
  @include flex-direction(row);
  @include media-breakpoint-down(xs) {
    min-height: calc(100vh - #{$mat-toolbar-height-mobile-portrait});
  }
}
// .side-panel-opened {
//height: 100vh!important;
//overflow: hidden;
//height: calc(100vh - #{$mat-toolbar-height-mobile-portrait})!important;
// }

// As per specs, mobile devices will use a different height for toolbars than for desktop.
// The height for mobile landscape devices has been ignored since relying on `@media orientation`
// is causing issues on devices with a soft-keyboard.
// See: https://material.io/guidelines/layout/structure.html#structure-app-bar
@media ($mat-xsmall) {
  .app-inner {
    height: fit-content;
  }
}

/* Boxed layout*/

.app.boxed {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .app.boxed {
    max-width: 46.88rem;
  }
}

@media (min-width: 62rem) {
  .app.boxed {
    max-width: 60.63rem;
  }
}

@media (min-width: 75rem) {
  .app.boxed {
    max-width: 73.13rem;
  }
}

.mat-drawer-content {
  height: 100%;
  // background-color: $grey-light;

  > .ps {
    position: relative;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    @include flex-direction(column);
    @include flexbox;
    @include flex(1);
    overflow-y: auto;
    overflow-x: hidden;
    padding: math.div($gutter, 3);
  }

  > .main-content {
    box-sizing: border-box;
    @include flex-direction(column);
    @include flexbox;
    @include flex(1);

    > .content-view {
      position: relative;
      box-sizing: border-box;
      padding: $gutter;
      @include flex(1 0 auto);
    }
  }
}

.app-dark .mat-drawer-content {
  background-color: rgba(53, 53, 53, 1);
}

@include media-breakpoint-up(md) {
  .side-panel-opened[dir="rtl"] .mat-drawer-content {
    margin-left: 0 !important;
    margin-right: $sidebar-width !important;
  }
}
