/** Animations **/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animate-flicker {
  animation: fadeIn 2s infinite alternate;
}

/** End of Animations **/

/** Events **/
.disabled-icon {
  display: none;
}

/** End of Events **/

/* Images */
.fms-img {
  width: 15px;
  height: 15px;
  box-sizing: content-box;
}

/** End of Images **/

/** Buttons **/
.fms-btn {
  cursor: pointer;
  height: 26px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #f15a2a;
  background: #fffaf8;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: #f15a2a;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

/** End of Buttons **/

/* Buttons List */
/* Element Item */
.fms-el-it {
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f15a2a;
  background: #fffaf8;
  height: 44px;
  margin: 0;
  padding: 0;
}

/* Label Element */
.fms-lbl-el {
  cursor: pointer;
  color: #f15a2a;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 12px 18px;
  margin: 0;
  border-right: 1px solid #f15a2a;
  text-transform: capitalize;
  white-space: nowrap;
}

/* Icon Element */
.fms-ic-el {
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fffaf8;
  margin: 6px;
  svg {
    width: 20px;
    height: 20px;
  }
}

/** End of Buttons List **/

/** Review Post **/
/* Review buttons container */
.fms-edt-cn {
  display: flex;
  gap: 5px;
}
.fms-qc-ci {
  margin: 0;
  padding: 0;
}
/* Review editor container */
.fms-qc-rv {
  position: relative;
  display: none;
  flex-direction: column;
  overflow-y: visible;
  overflow-x: auto;
  padding-bottom: 5px;
  padding-top: 5px;
}

/** End of Review Post **/

/** Editor **/
/* Editor container */
.fms-qc-ed {
  display: flex;
  flex-direction: column;
  width: calc(100% - 71px);
  margin-top: 10px;
}

/* Editor Input */
.fms-qc-txt {
  padding: 8px 8px 14px 8px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #f15a2a;
  background: #fffaf8;
  width: 100%;
  color: #2a1e36;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  resize: vertical;
  max-height: 140px;
  height: 50px;
  min-height: 50px;
  overflow-x: hidden;
}

/* Label Enter */
.fm-lbl-ent {
  color: #4d4d4d;
  font-size: 10px;
  margin-top: 3px;
}

/* Editor Rounder */
.fm-ed-rnd {
  display: flex;
}

/* Editor buttons container */
.fms-ed-bcn {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

/* Send Button */
.fm-ic-btn {
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  height: 100%;
  background: #f15a2a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 8px;
}

/* Send Icon */
.fm-ic-snd {
  cursor: pointer;
  width: 15px;
  height: 15px;
}

/** End of Editor **/

/* Loading Container */
/* Loading Container */
.fms-qc-lo {
  display: flex;
}

/* Loading Label */
.fm-lbl-loa {
  color: #2a1e36;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 10px;
  margin-right: 6px;
  display: flex;
  align-items: center;
}

/* Stop Generating Button */
.fm-btn-stg {
  display: flex;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #f15a2a;
  align-items: center;
}

/* Button Label */
.fm-lbl-btn {
  cursor: pointer;
  color: #f15a2a;
  margin: 0 6px;
  font-size: 12px;
}

/** End of Loading Container **/

/** Blocker Element **/
.fms-qc-blk {
  position: absolute;
  z-index: 999;
  top: 2px;
  right: 0;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #f15a2a;
  background: #fffaf8;
  padding: 0px 12px;
  cursor: pointer;
}

.fms-qc-blk-msg {
  color: #f15a2a;
  font-size: 12px;
  font-weight: 400;
  height: 34px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
}

/** End of Blocker Element **/

/* Upgrade Plan */
.fm-plan-up {
  border-radius: 40px;
  background: #f15a2a;
  margin-left: auto;
  color: #fff;
  font-family: "Arial";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px;
  margin: auto 0 auto auto;
}

/* Ai LOGO */
.fms-ai-logo {
  display: flex;
  align-items: center;
}

/* AI label */
.fms-lbl-ail {
  margin: 0 6px 0 0;
  font-size: 18px;
  font-weight: 700;
}

/* AI Img */
.fms-ai-img {
  width: 18px;
  height: 18px;
}

/* List Container */
.fms-qc-c {
  margin-top: 10px;
  align-items: center;
  justify-content: initial;
  gap: 5px;
  display: flex;
  width: fit-content;
}

/* List Element */
.fms-qc-el {
  min-height: 38px;
  width: 100%;
  position: relative;
  margin-right: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px 0px 0px;
  scroll-behavior: smooth;
  flex-wrap: wrap;
}

.hideOverflow {
  overflow: hidden !important;
}

/* Arrow Left */
.fm-ar-l {
  cursor: auto;
  width: 18px;
  height: 18px;
  opacity: 0.3;
  transform: rotate(180deg);
}

/* Arrow Right */
.fm-ar-r {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Inner Menu */
.fm-in-mn {
  position: absolute;
  left: -110px;
  width: 110px;
  border-radius: 8px 0 0 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
  display: none;
  flex-direction: column;
  overflow: hidden;
}

/* Inner Dialog Item */
.fm-fl-it {
  white-space: nowrap;
  margin: 0;
  color: #2a1e36;
  font-weight: 400;
  font-family: "Arial";
  font-size: 12px;
  cursor: pointer;
  padding: 10px 10px;
}

/* Float Menu Item */
.fm-fl-df {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  position: relative;
}

/* Plan Text */
.fm-fl-txt {
  white-space: nowrap;
  margin: 0 0px 0px 5px;
  color: #2a1e36;
  font-weight: 400;
  font-family: "Arial";
  font-size: 12px;
}

/* Menu Arrow Icon */
.fm-arr-ic {
  margin-left: auto;
}

/* Float Menu Item */
.fm-fl-mi {
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* Menu Item 1 */
.fm-fl-mi1 {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

/* Float Menu */
.fm-fl-mn {
  display: none;
  overflow: visible;
  padding: 0;
  bottom: 32px;
  right: 11px;
  position: absolute;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  z-index: 999;
  border: 1px solid #f0f0f0;
  background: #fff;
  flex-direction: column;
  min-width: 180px;
}

/* Float Menu Button */
.fm-fl-btn {
  position: relative;
  display: flex;
  cursor: pointer;
  font-size: 28px;
  margin-right: -5px;
  gap: 5px;
  padding: 0 0 0 10px;
  flex-direction: column;
}

/* Float Menu Icon */
.fm-fl-ic {
  cursor: pointer;
  width: 20px;
  margin: auto;
  height: 20px;
}

/* Fly Write Container */
.fms-qc-cont {
  position: relative;
  justify-content: center;
  margin: 5px auto 0;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

/* Fly Write (POST) Container */
.fms-qc-cont-post {
  margin: 1.6rem 3rem 0;
  max-width: 92%;
}

/* Fly Write Content */
.fms-qc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Fly Write Inner Content */
.fms-qc-i {
  display: contents;
}

/* Fly Write image */
.fms-qc-img {
  width: 32px;
  height: 32px;
  padding: 5px;
  box-sizing: content-box;
}

.fms-qc-img img {
  padding: 0 5px 0 0;
}

/* Main Styles */
.fms-qc-txt::-webkit-scrollbar {
  display: block;
}

.fms-qc-txt::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
}

.editor .flyMenuBtn {
  margin-top: 40px;
  margin-bottom: auto;
}

.editor .flyImg {
  margin-top: 40px;
  margin-bottom: auto;
}

.disabled-btn {
  cursor: auto !important;
  color: #959595 !important;
  border-color: #e3e3e3 !important;
  background: #f2f2f2 !important;
}

.disabled-btn .icon {
  display: none !important;
}

.disabled-btn .disabled-icon {
  display: unset !important;
}

.fms-qc-el::-webkit-scrollbar {
  display: none;
}

.fms-qc-el::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
}

#fmsQc::-webkit-scrollbar {
  display: none;
}

#fmsQc::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
}

.login .fms-qc-ed {
  display: none !important;
}

.login .fms-qc-txt {
  display: none !important;
}

.login .fms-qc-rv {
  display: none !important;
}

.login .fms-qc-lo {
  display: none !important;
}

.login .fms-qc-blk {
  display: none !important;
}

.login .fm-fl-it-r {
  display: flex !important;
}

.login .fm-fl-it {
  display: none !important;
}

.login .fm-fl-se {
  display: none !important;
}

.login .fms-qc-log {
  display: flex !important;
}

.login .fms-qc-c {
  display: none !important;
}

.login .fms-qc-el {
  display: none !important;
}

.fm-fl-it-r {
  display: none !important;
}

.fm-fl-it-r:hover {
  background: #f5f5f5;
}

.fm-fl-it:hover {
  background: #f5f5f5;
}

.disabled .fms-qc-ed {
  display: none !important;
}

.disabled .fms-qc-txt {
  display: none !important;
}

.disabled .fms-qc-rv {
  display: none !important;
}

.disabled .fms-qc-lo {
  display: none !important;
}

.disabled .fms-qc-blk {
  display: none !important;
}

.disabled .fms-qc-c {
  display: none !important;
}

.disabled .fms-qc-el {
  display: none !important;
}

.disabled .fm-fl-it-r {
  display: flex !important;
}

.disabled .fm-fl-it {
  display: none !important;
}

.disabled .fm-fl-se {
  display: none !important;
}

.fm-fl-mi:first-of-type .fm-fl-it:first-of-type {
  border-radius: 8px 8px 0 0;
}

.fm-fl-mi:last-of-type .fm-fl-it:last-of-typ {
  border-radius: 0 0 8px 8px;
}

.fm-fl-it-r {
  border-radius: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animate-flicker {
  animation: fadeIn 2s infinite alternate;
}

.animated-blocker {
  animation: fadeIn 2s infinite alternate;
  opacity: 0.8;
}
