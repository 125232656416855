.mat-accordion {
  .number {
    background-color: rgba(0, 0, 0, 0.05);
    color: mat-color($primary);
    margin-top: 15px;
    width: 32px;
    padding-left: 4px;
  }

  .mat-expansion-panel-header,
  .mat-expansion-indicator {
    position: relative;
  }

  .mat-expansion-indicator {
    right: -8px;
  }

  .mat-expansion-indicator {
    &:after,
    &:before {
      color: mat-color($warn);
    }
  }

  .text-title {
    margin-bottom: 5px;
  }

  .panel-title {
    transition: opacity linear 0.225s;

    .shortcut-text {
      color: mat-color($primary);
      background-color: $primary-light;
      border: 1px dashed;
      padding: 3px 8px;
      display: inline-block;
    }

    .shortcut-title {
      color: $black;
    }
  }

  .panel-description {
    margin-top: 6px;
    max-height: 20px;
    overflow: hidden;
  }

  .mat-expansion-panel {
    margin: 0 0 15px 0px;
    border-radius: 12px !important;

    &.mat-expanded {
      background-color: $primary-light;
      transition: background linear 0.225s;

      .panel-title {
        opacity: 0;
      }

      .mat-form-field-outline {
        background-color: $white;
      }
    }

    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        top: -20px;
      }
    }

    // hover and key up/down effects
    & .mat-expansion-panel-header.cdk-keyboard-focused,
    & .mat-expansion-panel-header.cdk-program-focused,
    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      &:not([aria-disabled="true"]) {
        background: $primary-light;
      }
    }
  }

  .mat-action-row {
    min-height: 56px;
    border-top-width: 0;
    padding: 0 24px 16px;

    button.mat-button-base {
      margin-left: 15px;

      svg-icon-sprite {
        display: inline-flex;
      }
    }

    .info-icon {
      height: 18px;
      width: 20px;
      font-size: 18px;
    }

    .saving-note {
      opacity: 0;

      &.saving {
        opacity: 1;
        transition: opacity linear 0.1s;
      }

      &.saved {
        opacity: 0;
        transition: opacity linear 4s;
      }
    }
  }
}
