textarea.mat-input-element {
    line-height: 1.5;
}

.mat-form-field{
    &.mat-form-field-appearance-outline .mat-form-field-outline-thick {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
            border-width: 1px;
        }
    }
}