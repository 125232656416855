// Default Theme
@include angular-material-theme($theme);

// Dark Theme
.app-dark {
  @include angular-material-theme($dark-theme);
  .navigation .mat-list-item {
    color: rgba(255,255,255, 0.87)!important;
  }
  mat-sidenav.sidebar-panel .navigation .amml-icon-arrow-container > mat-icon {
    border-top: 5px solid rgba(255, 255, 255, 0.87);
  }
}
