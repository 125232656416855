/* $sidebar-panel
 ------------------------------------------*/
$mat-toolbar-height-desktop: 64px !default;
$mat-toolbar-height-mobile-portrait: 56px !default;
$mat-toolbar-height-mobile-landscape: 48px !default;

div[dir="ltr"] .amml-icon,
.amml-icon {
  @include margin-right(24px !important);
}

mat-sidenav.sidebar-panel {
  position: absolute !important;
  overflow-x: hidden;
  width: $sidebar-width;
  border-width: 0;
  background-color: $primary-dark;
  box-shadow: 0px 0px 16px #00000080;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

  .mat-divider {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    position: fixed !important;
    top: $mat-toolbar-height-mobile-portrait;
  }

  .mat-drawer-focus-trap > .cdk-focus-trap-content {
    position: relative;
  }

  > nav {
    min-width: $sidebar-width;
  }

  .mat-list-item .mat-list-item-content {
    display: flex;
    height: auto;
    max-height: 48px;
    overflow: hidden;
    padding: 0 16px;
    transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    width: 100%;
  }

  .mat-list-item {
    height: auto;
    transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);

    &:hover {
      background: mat-color($background, "hover") !important;
    }
  }

  .selected-amml-item,
  .active-amml-item {
    background: mat-color($background, "hover") !important;
  }

  .mat-nav-list a,
  .anml-data {
    height: 48px;
  }

  .navigation {
    .amml-icon-arrow-container > mat-icon {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(mat-color($foreground, base), 0.87);
      text-indent: -9999px;
    }

    .selected-amml-item,
    .active-amml-item {
      background: mat-color($background, "hover");

      & ~ div[class*=" level-"],
      & ~ div[class^="level-"] {
        background: mat-color($background, "hover");
      }
    }

    div.level-1 .mat-list-item-content {
      @include padding-left(64px);
    }

    div.level-2 .mat-list-item-content {
      @include padding-left(64px);
    }

    div.level-3 .mat-list-item-content {
      @include padding-left(64px + 32px);
    }

    div.level-4 .mat-list-item-content {
      @include padding-left(64px + 48px);
    }
  }
}

@include media-breakpoint-up(md) {
  /* Collapsed sidebar */
  .collapsed-sidebar {
    .mat-drawer-backdrop {
      display: none;
    }

    &.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
      margin-left: $collapsed-sidebar-width !important;
    }

    mat-sidenav.sidebar-panel {
      transition:
        width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    }

    mat-sidenav.sidebar-panel:not(:hover) {
      width: $collapsed-sidebar-width;

      .level-1,
      .level-2,
      .level-3,
      .level-4,
      .label,
      .amml-icon-arrow-container {
        display: none !important;
        visibility: hidden;
      }

      .mat-list-item > .mat-list-item-content > a {
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }

      .material-icons {
        padding: 0;
        margin: 0;
      }
    }
  }
}

[dir="rtl"] {
  mat-sidenav.sidebar-panel {
    .navigation {
      div.level-1 .mat-list-item-content {
        @include padding-right(64px);
        @include padding-left(16px);
      }

      div.level-2 .mat-list-item-content {
        @include padding-right(64px);
        @include padding-left(16px);
      }

      div.level-3 .mat-list-item-content {
        @include padding-right(64px + 32px);
        @include padding-left(16px);
      }

      div.level-4 .mat-list-item-content {
        @include padding-right(64px + 48px);
        @include padding-left(16px);
      }

      mat-icon:not(.menu-caret) {
        @include margin-left(24px);
        @include margin-right(0 !important);
      }

      .menu-caret {
        @include float(left);
        @include margin-right(0.3125rem);
        @include margin-left(0);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  [dir="rtl"] {
    &.collapsed-sidebar,
    .collapsed-sidebar {
      &.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
        margin-left: 0 !important;
        margin-right: $collapsed-sidebar-width !important;
      }

      mat-sidenav.sidebar-panel:not(:hover) {
        .mat-list-item > .mat-list-item-content > a > span.menu-badge {
          right: auto;
          left: 16px;
        }
      }
    }
  }
}
