@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";
@import "flyai/main-theme";

* {
  box-sizing: border-box;
}

.category-container {
  padding: 15px;

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .top-space {
    margin-top: 20px;
  }

  table.mat-table {
    width: 100%;
    min-width: 600px;
    overflow: scroll;
  }
}

app-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logo-icon {
  width: 100%;
  max-width: 170px;
}

.small-icon {
  vertical-align: top;
  display: inline-flex;
  margin-right: 5px;
}

.mat-menu-panel {
  margin-top: 5px !important;
  // overflow: hidden !important;
}

mat-dialog-container.mat-dialog-container {
  .right-space {
    margin-right: 10px;
  }

  .top-gap {
    margin-top: 15px;
  }
}

.profile-menu {
  margin-right: 20px;

  svg.icon {
    vertical-align: middle;
  }
}

mat-card.mat-card {
  margin-top: 20px;

  .mat-card-header-text {
    margin-left: 0;
  }
}

.right-gap,
button.right-gap {
  margin-right: 8px;
}

.container {
  margin: 30px 20px 10px;

  h3 {
    margin: 1em 0;

    @media (min-width: 600px) {
      margin-left: 20px;
    }
  }
}

.text-title label {
  color: $black;
}

.button-row {
  margin-bottom: 10px;
}

.video-dialog .mat-dialog-container {
  padding: 0;
  margin: 0;
}
.video-dialog {
  width: 600px !important;
  height: 485px !important;

  .mat-dialog-container {
    // padding: 25px !important;
    border-radius: 16px !important;
  }
}
.black-bg {
  background-color: black !important;
  max-height: 70vh !important;
  // width: 400px !important;
  .mat-dialog-container {
    background-color: black !important;
    // padding-bottom: 0 !important;
    border-radius: 16px !important;
  }
}
.welcome-dialog {
  width: 400px !important;
  .mat-dialog-container {
    padding-bottom: 0 !important;
    border-radius: 16px !important;
  }
}

.advanced-search {
  // margin-left: 64px !important;

  .mat-dialog-container {
    transform: translate(25px, 25px) !important;
    @media screen and (min-width: 1200px) {
      // margin-left: -178px !important;
    }
  }
}
.scroll-button {
  color: rgba(0, 0, 0, 0.54);
}

.empty-state {
  text-align: center;
  height: 200px;
  /* width: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 100%;
    max-width: 500px;
    height: 100% !important;
    opacity: 0.5;
  }

  h3 {
    color: mat-color($warn);
    margin: 0;
    font-size: 20px;
  }

  label {
    color: #808080;
  }
}

.blur-effect {
  opacity: 0.3;
}

.mat-icon.help-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
  cursor: help;
}

.profile-img {
  width: 35px;
  height: 35px;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.no-color.disabled {
  background: none;
  box-shadow: none;
  color: #9f9f9f;
}

.mat-sidenav-container{
  background-color: #FFF;
  height: 100%;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f4f7f9 !important;
}

.mat-drawer-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-left: 64px;
  height: 100vh !important;
  .mini-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .advanced-search {
    // margin-left: 240px !important;
  }
  .expand-action {
    // margin-top: -30px;
    padding-bottom: 30px;
    flex-direction: column !important;
    gap: 15px !important;
  }

  &[style*="margin-left: 240px"] {
    .dashboard-grid {
      grid-template-columns: 1.7fr 2fr;
    }
    .mini-sidebar {
      display: none;
    }
    .expand-form {
      flex-wrap: wrap;
    }

    .expand-btns {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .expand-version {
      max-width: 10% !important;
    }

    .expand-action {
      flex-direction: column !important;
      gap: 15px !important;
    }
    .mat-accordion .mat-action-row button.mat-button-base {
      margin-left: unset !important;
      font-size: 12px;
    }
  }
}

.mat-expansion-panel {
  margin-left: 5px;
  background: #fff !important;
}
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  margin-left: 5px;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #86898b33;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #86898b;
  border-radius: 6px;
  border: 1px solid #8f86a7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #7f8182;
}

// /* width */
// ::-webkit-scrollbar {
//   width: 11px;
//   background: transparent;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
//   border-radius: 16px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.mat-tab-header {
  margin: 40px 0 !important;
}

.mat-tooltip.toolTip {
  width: auto;
  // height: 42px;
  left: 1033px;
  top: 467px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: white !important;
  background: #2a1e36;
}

.signal-tooltip {
  width: 150px !important;
}
// .myPanelClass {
//   margin-top: 30px !important;
// }
.v-container {
  margin: 0 25px;
}

.dateClass {
  min-width: calc(100% + 42px) !important;
}

.popover-body {
  background-color: #2a1e36;
  color: white;
  border-radius: 8px;
}

.popover-arrow:after {
  border-top-color: #2a1e36 !important;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .popover-arrow,
.bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .popover-arrow::before,
.bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after,
.bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .popover-arrow,
.bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .popover-arrow::before,
.bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after,
.bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.ngxp__container[x-placement^="right"] {
  overflow: visible;
}

.sidemenu-tooltip-flylearning,
.sidemenu-tooltip-cat,
.sidemenu-tooltip,
.sidemenu-tooltip-flyshare {
  max-height: 90vh;
  left: 56px !important;
}

.hide-lastpass{
  div[data-lastpass-icon-root] {
    display: none;
  }
}

.mat-paginator-range-actions {
  margin-right: auto;  /* Align paginator to the left */
}

.tour-last-step {
  .tour-step {
    .mat-card-actions {
      display: none;
    }
  }
}

.ngx-ui-tour_backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.tour-step {
  .mat-card {
    background-color: #2A1E36;
    color: #fff;
    margin-top: 0px !important;

    .mat-card-actions {
      .mat-button-disabled{
        opacity: 0;
      }

      .mat-button-base {
        padding-left: 0px;
        padding-right: 0px;
        background-color: #F15A2A;
        color: #fff;
        
        &.prev {
          background-color: #FFF;
          color: #F15A2A;
        }
      }

      .mat-icon {
        display: none;
      }
    }

    .mat-card-title {
      .close {
        display: none;
      }

      .title-text {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}