/* $header
 ------------------------------------------*/
mat-toolbar {
  // .toolbar-avatar {
  //   width: 40px;
  //   height: 40px;
  //   line-height: 24px;

  //   img {
  //     width: 40px;
  //     border-radius: 50%;
  //   }
  // }

  // .notification-label {
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   font-size: 12px;
  //   font-weight: 700;
  //   line-height: 13px;
  //   border-radius: 50%;
  //   width: 13px;
  //   height: 13px;
  //   background-color: mat-color($warn);
  //   border: 4px solid mat-color($warn);
  //   color: white;
  //   text-align: center;
  // }

  &.main-header {
    padding: 0 8px;
    position: relative;
    background-color: $white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    z-index: 9;
    height: 85px;

    @include media-breakpoint-down(xs) {
      position: fixed;
      width: 100%;
      top: 0;
    }

    .user-name {
      max-width: 200px;
    }

    .licence-badge {
      margin: 0 12px 0 15px;
      background-color: $black;
      padding: 0 15px;
      line-height: 34px;
      border-radius: 2px;
    }

    // .branding {
    //   display: -webkit-box;
    //   display: -moz-box;
    //   display: -webkit-flex;
    //   display: -ms-flexbox;
    //   display: flex;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    //   margin: auto 0;
    //   line-height: 50px;
    //   padding: 0 64px 0 16px;
    // }

    // .logo img{
    //   width: 100px;
    //   height: auto;
    //   vertical-align: middle;
    // }

    // .search-bar {
    //   .search-form {
    //     background: rgba(255, 255, 255, 1);
    //     position: relative;
    //     border-radius: $border-radius-large;
    //     margin-right: $gutter;
    //     display: block;
    //     max-width: 800px;

    //     input {
    //       font-size: 1rem;
    //       padding: .95rem .75rem;
    //       z-index: 2;
    //       cursor: text;
    //       text-indent: 30px;
    //       border: none;
    //       background: transparent;
    //       width: 100%;
    //       outline: 0;
    //     }

    //     .material-icons {
    //       position: absolute;
    //       top: 50%;
    //       left: 10px;
    //       margin-top: -12px;
    //       color: rgba(mat-color($foreground, base), 0.87);
    //     }
    //   }
    // }
  }
}

// Specific height for mobile devices in portrait mode.
// @media ($mat-xsmall) and (orientation: portrait) {
//   mat-toolbar {
//     &.main-header {
//       .branding {
//         padding: 0 16px 0 16px;
//       }

//       .logo {
//         width: 87px;
//         height: 50px;
//         -webkit-background-size: 87px 50px;
//         background-size: 87px 50px;
//       }
//     }
//   }
// }

// [dir="rtl"] {
//   .main-header {
//     .branding {
//       padding: 0 16px 0 64px;
//     }

//     .search-bar {
//       .search-form {
//         .material-icons {
//           left: auto;
//           right: 10px;
//         }
//       }
//     }
//   }
// }
