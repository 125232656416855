/* $error-pages
 ------------------------------------------*/
@import './../app.variables';
@import './../material.variables';
 .session {
  width: 100%;
  position: relative;
  z-index: 4000;
  @include flexbox;
  @include flex-direction(column);
  background-size: cover;
  background-attachment: fixed;

  .button-white {
    margin-bottom: 10px;
    color: $white;
  }
}

.brands {
  z-index: 4000;
  background: url('/assets/images/Purple-Gradient-BG.png') center center;
  background-position: left top;
  background-size: cover;
}

.brand-txt {
  font-size: 35px;
  letter-spacing: 0px;
  line-height: 44px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Arial";
  text-align: center;
  @media (max-width: 1530px) {
    font-size: 23px;
  }
}

.session-content {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex(1 0 auto);
  @include flex-direction(column);
}

.session-wrapper {
  @include flex(none);
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  .logo {
    width: 150px;
    height: auto;
  }
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  @include flex(none);
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: mat-color($primary);
  color: #fff;
  padding: 0 $gutter;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 $gutter;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: mat-color($primary);
    border-width: 8px;
    margin-left: -8px;
  }
}

.error-subtitle {
  font-size: 32px;
  font-weight: 300;
}

.logo {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  @media (min-width: 1530px) {
    background-size: inherit;
  }
}

.svc-policy-txt {
  font-size: 13px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #656565;
  margin-block-start: 2em;
  margin-block-end: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dadada;
}

.separator:not(:empty)::before {
  margin-right: .75em;
}

.separator:not(:empty)::after {
  margin-left: .75em;
}